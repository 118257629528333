

import { Card } from "day8-ui"
import Image from "next/image"
interface HeroProps {
    children: React.ReactNode
    image?: {
        url?: string
        alt?: string
    }
    video?: {
        url?: string
        poster?: string
    }
    mobileVideo?: {
      url?: string
      poster?: string
    }
}

const Hero = ({children, image, video, mobileVideo}: HeroProps) => {
    return (
        <Card className="dui-w-full dui-min-h-[85vh] dui-flex dui-flex-col dui-justify-center dui-items-center dui-text-center dui-overflow-hidden dui-relative">
          {image && image.url && <Image className="dui-absolute dui-left-0 dui-top-0 dui-w-full dui-h-full dui-object-cover dui-brightness-75" src={image.url} alt={image?.alt || "hero image"} width={1920} height={1080} />}
          {video && video.url && (
            <>
            <video
            className="lg:dui-hidden dui-absolute dui-top-0 dui-left-0 dui-w-full dui-h-full dui-object-cover dui-brightness-50"
            autoPlay
            muted
            loop
            playsInline
            poster={mobileVideo?.poster || video.poster}
          >
            <source src={mobileVideo?.url || video.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video
            className="dui-hidden lg:dui-block dui-absolute dui-top-0 dui-left-0 dui-w-full dui-h-full dui-object-cover dui-brightness-50"
            autoPlay
            muted
            loop
            playsInline
            poster={video.poster}
          >
            <source src={video.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          </>
          )}
          <div className='dui-relative dui-max-w-full'>
            {children}
          </div>
        </Card>
    )
}

export default Hero