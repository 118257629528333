import moment from "moment";
import { useEffect, useState } from "react";

interface CountdownTextProps {
    endDate: string;
    text?: string;
    completedText?: string;
}
interface TimeLeft {
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const CountdownText = ({ endDate, text, completedText }: CountdownTextProps) => {
    const [timeLeft, setTimeLeft] = useState<TimeLeft>({
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });
    text = text || "UNTIL BOOKINGS OPEN FOR 2025";
    completedText = completedText || "BOOKINGS NOW OPEN";
    const calculateTimeLeft = (endDate: string) => {
        if (!endDate) return {
            weeks: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
        const parsedEndDate = moment.utc(endDate, "DD/MM/YYYY h:mm a");
        const now = moment.utc();

        const difference = parsedEndDate.diff(now);
        if (difference > 0) {
            const weeks = parsedEndDate.diff(now, 'weeks');
            const days = parsedEndDate.diff(now, 'days') % 7;
            const hours = parsedEndDate.diff(now, 'hours') % 24;
            const minutes = parsedEndDate.diff(now, 'minutes') % 60;
            const seconds = parsedEndDate.diff(now, 'seconds') % 60;
            return ({
                weeks,
                days,
                hours,
                minutes,
                seconds
            })
        } else {
            return {
                weeks: 0,
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft(calculateTimeLeft(endDate));
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [endDate]);
    const showTimeLeft = timeLeft.weeks + timeLeft.days + timeLeft.hours + timeLeft.minutes + timeLeft.seconds > 0

    if (!showTimeLeft) {
        return <span className="dui-text-lg">{completedText}</span>
    }
    return (
        <div className="dui-text-5xl dui-text-secondary-500">
            <div className="dui-flex dui-items-end dui-justify-center dui-gap-6 sm:dui-gap-8">
                <div className="dui-flex dui-flex-col dui-items-center dui-gap-1">
                    <span className="dui-text-xs">Weeks</span>
                    <span>{`${timeLeft?.weeks.toString().padStart(2, '0')}`}</span>
                </div>
                <div className="dui-flex dui-flex-col dui-items-center dui-gap-1">
                    <span className="dui-text-xs">Days</span>
                    <span>{`${timeLeft?.days.toString().padStart(2, '0')}`}</span>
                </div>
                <div className="dui-flex dui-flex-col dui-items-center dui-gap-1">
                    <span className="dui-text-xs">Hours</span>
                    <span>{`${timeLeft?.hours.toString().padStart(2, '0')}`}</span>
                </div>
                <div className="dui-flex dui-flex-col dui-items-center dui-gap-1">
                    <span className="dui-text-xs">min</span>
                    <span>{`${timeLeft?.minutes.toString().padStart(2, '0')}`}</span>
                </div>
                <div className="dui-flex dui-flex-col dui-items-center dui-gap-1">
                    <span className="dui-text-xs">sec</span>
                    <span>{`${timeLeft?.seconds.toString().padStart(2, '0')}`}</span>
                </div>
            </div>
            {/* <span className="dui-color-secondary">{text}</span> */}
        </div>
    )
};

export default CountdownText;
